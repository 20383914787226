export class Utils {
  static GetUserImageSrcAttribute(
    userImage: string | null,
    userGender: string,
    defaultImagesPath: string
  ): string {
    let userImageSrcAttribute = "";
    let userImageSrc = "";
    if (userImage == null || userImage == "") {
      switch (userGender) {
        case "M":
          userImageSrc = "profile-boy.png";
          break;
        case "F":
          userImageSrc = "profile-girl.png";
          break;
        default:
          userImageSrc = "persona.png";
          break;
      }
    } else {
      userImageSrc = userImage;
    }

    userImageSrcAttribute = `src="${defaultImagesPath + userImageSrc}"`;
    return userImageSrcAttribute;
  }

  static GetAttrString(element: HTMLElement, name: string): string {
    return element.getAttribute(name) ?? "";
  }

  static GetAttrBoolean(element: HTMLElement, name: string): boolean {
    return element.getAttribute(name) === "true" ?? "";
  }
}
