import { Utils } from "../Util";

export class NavBar extends HTMLElement {
  private _siteTitle: string = "";
  private _showSideBarToggler: boolean = true;
  private _userImage: string | null = "";
  private _userFirstName: string = "";
  private _userSurName: string = "";
  private _userGender: string = "";
  private _notifications: boolean = false;
  private _isAuthenticated: boolean = false;
  private _defaultImagesPath: string = "";
  private _loginUrl: string | null = null;
  private _registrationAllowed: boolean = false;
  private _registrationUrl: string | null = null;
  private _profileUrl: string | null = null;
  private _logoutUrl: string | null = null;
  private _showIcons: boolean = false;
  private _homeUrl: string | null = null;

  constructor() {
    super();
  }

  connectedCallback() {
    this._siteTitle = Utils.GetAttrString(this, "data-site-title");
    this._showSideBarToggler = Utils.GetAttrBoolean(
      this,
      "data-show-sidebar-toggler"
    );
    this._userImage = Utils.GetAttrString(this, "data-user-image");
    this._userGender = Utils.GetAttrString(this, "dat-user-gender");
    this._userFirstName = Utils.GetAttrString(this, "data-user-firstname");
    this._userSurName = Utils.GetAttrString(this, "data-user-surname");
    this._notifications = Utils.GetAttrBoolean(
      this,
      "data-enable-notifications"
    );
    this._isAuthenticated = Utils.GetAttrBoolean(this, "data-is-authenticated");
    this._defaultImagesPath = Utils.GetAttrString(
      this,
      "data-default-images-path"
    );
    this._registrationAllowed = Utils.GetAttrBoolean(this, "data-registration-allowed");
    this._registrationUrl = Utils.GetAttrString(this, "data-registration-url");
    this._loginUrl = Utils.GetAttrString(this, "data-login-url");
    this._profileUrl = Utils.GetAttrString(this, "data-profile-url");
    this._logoutUrl = Utils.GetAttrString(this, "data-logout-url");
    this._showIcons = Utils.GetAttrBoolean(this, "data-show-icons");
    this._homeUrl = Utils.GetAttrString(this, "data-home-url");
    this.render();
  }

  static toggleSideBar() {
    const sideBar: HTMLElement = document.querySelector(
      ".bt-sidebar"
    ) as HTMLElement;
    const page: HTMLElement = document.querySelector(".bt-page") as HTMLElement;
    const navBar: HTMLElement = document.querySelector(
      ".bt-navbar"
    ) as HTMLElement;
    const footer: HTMLElement = document.querySelector(".bt-footer") as HTMLElement;

    sideBar.classList.toggle("bt-sidebar-shown");
    page.classList.toggle("bt-sidebar-shown");
    navBar.classList.toggle("bt-sidebar-shown");
    if (footer != null) {
      footer.classList.toggle("bt-sidebar-shown");
    }
  }

  render() {
    let sidebarToggler = "";
    if (this._showSideBarToggler) {
      sidebarToggler = `
        <button class="navbar-toggler d-block" type="button" aria-label="Toggle sidebar" onclick="BTWebComponents.NavBar.toggleSideBar()">
            <span class="navbar-toggler-icon"></span>
        </button>
      `;
    }

    let notificationsSection = "";
    if (this._isAuthenticated && this._notifications) {
      /*
Notification example
<div class="list-group-item">
                      <div class="row align-items-center">
                          <div class="col-auto"><span
                                  class="status-dot status-dot-animated bg-red d-block"></span></div>
                          <div class="col text-truncate">
                              <a href="#" class="text-body d-block">Example 1</a>
                              <div class="d-block text-muted text-truncate mt-n1">
                                  Change deprecated html tags to text decoration classes (#29604)
                              </div>
                          </div>
                          <div class="col-auto">
                              <a href="#" class="list-group-item-actions">
                                  <!-- Download SVG icon from http://tabler-icons.io/i/star -->
                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon text-muted"
                                      width="24" height="24" viewBox="0 0 24 24" stroke-width="2"
                                      stroke="currentColor" fill="none" stroke-linecap="round"
                                      stroke-linejoin="round">
                                      <desc>Download more icon variants from
                                          https://tabler-icons.io/i/star</desc>
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                      <path
                                          d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z">
                                      </path>
                                  </svg>
                              </a>
                          </div>
                      </div>
                  </div>
        */

      notificationsSection = `
        <div class="nav-item dropdown me-3">
            <a href="#" class="nav-link px-0" data-bs-toggle="dropdown" abindex="-1"
                aria-label="Show notifications" aria-expanded="false">
                <i class="bi bi-bell">
                    <span class="d-none position-absolute start-100 translate-middle p-1 bg-danger border border-light rounded-circle">
                        <span class="visually-hidden">New alerts</span>
                    </span>
                </i>
            </a>
            <div
                class="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card dropdown-notifications p-0 border-0">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Notifications</h3>
                    </div>
                    <div class="list-group list-group-flush list-group-hoverable">
                    </div>
                </div>
            </div>
        </div>
      `;
    }

    let titleSection = `
      <a class="navbar-brand d-flex align-items-center ms-2" href="${this._homeUrl != null ? this._homeUrl : "#"}">
          ${this._siteTitle.trim() != "" ? this._siteTitle : "Home"}
      </a>
    `;

    let userSection = "";

    if (this._isAuthenticated) {
      let userImageSrcAttribute = Utils.GetUserImageSrcAttribute(
        this._userImage,
        this._userGender,
        this._defaultImagesPath
      );

      let userActions = "";

      if (this._profileUrl != null && this._profileUrl != "") {
        userActions += `<a href="${this._profileUrl}" class="dropdown-item">Profile</a>`;
      }

      if (this._logoutUrl != null && this._logoutUrl != "") {
        if (userActions != "") {
          userActions += `<li><hr class="dropdown-divider"></li>`;
        }
        userActions += `<a href="${this._logoutUrl}" class="dropdown-item">Logout</a>`;
      }

      let userActionsMenu = "";

      if (userActions != "") {
        userActionsMenu = `
					<div class="dropdown-menu dropdown-menu-end shadow">
						${userActions}
					</div>`;
      }

      userSection = `
        <div class="nav-item dropdown">
          <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu" aria-expanded="false">
            <img class="avatar rounded-circle" ${userImageSrcAttribute}></span>
            <div class="d-block ps-2">
              <div>${this._userFirstName ?? ""}</div>
              <div>${this._userSurName ?? ""}</div>
              <!-- <div class="mt-1 small text-muted">UI Designer</div> -->
            </div>
          </a>
          ${userActionsMenu}
        </div>
      `;
    } else {
      let registrationLink = "";
      let loginLink = "";
      let createNav = false;

      if (this._registrationAllowed && this._registrationUrl != null && this._registrationUrl != "") {
        let registerText = `${this._showIcons ? `<i class="bi bi-person-plus"></i>` : ``
          } Register`;
        registrationLink += `
          <li class="nav-item">
            <a href="${this._registrationUrl}" class="nav-link text-dark">${registerText}</a>
          </li>
        `;
        createNav = true;
      }
      if (this._loginUrl != null) {
        let loginText = `${this._showIcons ? `<i class="bi bi-box-arrow-in-left"></i>` : ``
          } Login`;
        loginLink += `
          <li class="nav-item">
            <a href="${this._loginUrl}" class="nav-link text-dark">${loginText}</a>
          </li>
        `;
        createNav = true;
      }

      if (createNav) {
        userSection = `
          <ul class="navbar-nav">
            ${registrationLink}
            ${loginLink}
          </ul>
        `;
      }
    }

    this.innerHTML = `
        <nav class="bt-navbar navbar navbar-expand fixed-top bg-white shadow-sm">
            <div class="container-fluid">
            ${sidebarToggler}
               ${titleSection}
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <div class="navbar-nav me-auto">
                    </div>
                    <div class="flex-row navbar-nav order-last d-flex align-items-center">
                        ${notificationsSection}
                        ${userSection}
                    </div>
                </div>
            </div>
        </nav>
    `;
  }
}
