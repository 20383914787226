import { Utils } from "../Util";

export class SideBar extends HTMLElement {
  private _userImage: string | null = "";
  private _userFirstName: string = "";
  private _userSurName: string = "";
  private _userGender: string = "";
  private _defaultImagesPath: string = "";
  private _isAuthenticated: boolean = false;
  private _loginUrl: string | null = null;
  private _registrationAllowed: boolean = false;
  private _registrationUrl: string | null = null;
  private _logoutUrl: string | null = null;
  private _profileUrl: string | null = null;
	private _showIcons: boolean = false;  

  constructor() {
    super();
  }

  connectedCallback() {
    this._userImage = Utils.GetAttrString(this, "data-user-image");
    this._userFirstName = Utils.GetAttrString(this, "data-user-firstname");
    this._userSurName = Utils.GetAttrString(this, "data-user-surname");
    this._defaultImagesPath = Utils.GetAttrString(
      this,
      "data-default-images-path"
    );
    this._isAuthenticated = Utils.GetAttrBoolean(this, "data-is-authenticated");
    this._defaultImagesPath = Utils.GetAttrString(
      this,
      "data-default-images-path"
    );
    this._registrationAllowed = Utils.GetAttrBoolean(this, "data-registration-allowed");
    this._registrationUrl = Utils.GetAttrString(this, "data-registration-url");
    this._loginUrl = Utils.GetAttrString(this, "data-login-url");
    this._profileUrl = Utils.GetAttrString(this, "data-profile-url");
    this._logoutUrl = Utils.GetAttrString(this, "data-logout-url");
		this._showIcons = Utils.GetAttrBoolean(this, "data-show-icons");
    this.render();
  }

  render() {
    let userActionContainer = "";
		let showDivider = false;

    if (this._isAuthenticated) {
			let userImageSrcAttribute = Utils.GetUserImageSrcAttribute(
				this._userImage,
				this._userGender,
				this._defaultImagesPath
			);

      let userActions = "";

      if (this._profileUrl != null && this._profileUrl != "") {
        userActions += `<a href="${this._profileUrl}" class="dropdown-item">Profile</a>`;
      }

      if (this._logoutUrl != null && this._logoutUrl != "") {
        if (userActions != "") {
          userActions += `<li><hr class="dropdown-divider"></li>`;
        }
        userActions += `<a href="${this._logoutUrl}" class="dropdown-item">Logout</a>`;
      }

			let userActionsMenu = "";

			if(userActions != ""){
				userActionsMenu = `
					<div class="dropdown-menu shadow">
						${userActions}
					</div>`;
			}

			userActionContainer = `
				<div class="dropdown">
					<a href="#" class="d-flex align-items-center link-dark text-decoration-none lh-1 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
						<img ${userImageSrcAttribute} alt="" class="avatar avatar rounded-circle me-2" />
						<div class="me-1">
							<div>${this._userFirstName ?? ""}</div>
							<div>${this._userSurName ?? ""}</div>
						</div>
					</a>
					${userActionsMenu}
				</div>
			`;

			showDivider = true;
    } else {
			let registerAction = "";
			let loginAction = "";
			
			if (this._registrationAllowed && this._registrationUrl != null && this._registrationUrl != "") {
				let registerText = `${this._showIcons ? `<i class="bi bi-person-plus"></i>` : ``} Register`;
				registerAction = `
					<li class="nav-item">
						<a href="${this._registrationUrl}" class="nav-link text-dark">${registerText}</a>
					</li>
				`;
				showDivider = true;
			}

			if (this._loginUrl != null && this._loginUrl != "") {
				let loginText = `${this._showIcons ? `<i class="bi bi-box-arrow-in-left"></i>` : ``} Login`;
				loginAction = `
					<li class="nav-item">
						<a href="${this._loginUrl}" class="nav-link text-dark">${loginText}</a>
					</li>
				`;
				showDivider = true;
			}

			userActionContainer = `
				<ul class="navbar-nav">
					${registerAction}
					${loginAction}
				</ul>
			`;
    }

    this.innerHTML = `
      <aside class="bt-sidebar d-flex flex-column flex-shrink-0 p-3 bg-white overflow-auto shadow">
        <ul class="nav nav-pills flex-column mb-auto"></ul>
        ${showDivider ? "<hr>" : ""}
        ${userActionContainer}
      </aside>
    `;
  }

  static toggleSidebar() {
    const sidebar = document.querySelector(".bt-sidebar");
    if (sidebar == null) {
      console.error("[BT.SideBar] No sidebar found");
    } else {
      sidebar.classList.toggle("show");
    }
  }
}
